import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Offer, OfferShortInfo } from 'src/app/core/models/offer.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

interface CounterOfferModalData {
  offer: any,
  shopPrice: number
}

@Component({
  selector: 'app-counter-offer-modal',
  templateUrl: './counter-offer-modal.component.html',
  styleUrls: ['./counter-offer-modal.component.scss']
})

export class CounterOfferModalComponent implements OnInit {
  offer = this.data.offer;
  shopPrice = 0;
  latestOffer = 0;

  valueControl = new FormControl(null, Validators.required);
  expiresOnControl = new FormControl<any>(null);

  today = new Date();

  constructor(public dialogRef: MatDialogRef<CounterOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CounterOfferModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.shopPrice = this.offer.carDetails ? this.offer.carDetails.shopPrice : this.data.shopPrice;
    this.latestOffer = this.offer.latestOffer ? this.offer.latestOffer: this.offer.value;
  }

  makeOffer() {
    if (this.valueControl.invalid) {
      this.snackbar.negativeSentiment('No value inserted!');
      return;
    }

    if (this.isExpired() && !this.valueControl.value) {
      this.snackbar.negativeSentiment('No expiration date');
      return;
    }

    this.dialogRef.close({
      value: this.valueControl.value,
      expiresOn: this.expiresOnControl.value ? this.expiresOnControl.value.format('yyyy-MM-DD') : null
    });
  }

  close() {
    this.dialogRef.close();
  }

  isExpired() {
    return moment(this.offer.expiresOn).isSameOrBefore(moment());
  }
}
