<div class="flex flex-col gap-6 text-sm sm:text-base relative">
  <div class="absolute top-0 right-1">
    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="sm:text-xl font-bold">Make offer</div>

  <div class="flex justify-between">
    <p>Selling price</p>

    <p>€{{car.sellingPrice | number}}</p>
  </div>

  <div class="flex flex-col xs:flex-row justify-between items-center">
    <p>Offer value €</p>

    <app-input-number componentId="new-offer-value" [control]="valueControl" placeholder="Insert new offered price €"></app-input-number>
  </div>

  <div class="flex flex-col xs:flex-row justify-between items-center">
    <p>Offer expiration date</p>


    <app-datepicker [control]="dateControl" [min]="today"></app-datepicker>
  </div>

  <app-blue-btn (clickEmitter)="makeOffer()" class="self-end">Create offer</app-blue-btn>
</div>
