import { Component, Input, OnInit } from '@angular/core';
import { CarDetails } from 'src/app/core/models/car.model';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';
import { ShopService } from 'src/app/core/services/shop.service';
import { UserService } from 'src/app/core/services/user.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BusinessCase, CarStatus } from 'src/app/core/models/utils.model';
import { CoreService } from 'src/app/core/services/core.service';
import { CounterOfferBody, CreateOfferBody, Offer } from 'src/app/core/models/offer.model';
import { OfferClientStatuses } from 'src/app/features/my-offers/my-offers.component';
import { CartService } from 'src/app/core/services/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateOfferModalComponent } from 'src/app/features/car-page/create-offer-modal/create-offer-modal.component';
import { OffersService } from 'src/app/core/services/offers.service';
import { CounterOfferModalComponent } from 'src/app/features/my-offers/counter-offer-modal/counter-offer-modal.component';

export enum VAT {
  IncludedVAT = 'Incl. non-refundable VAT',
  ExVAT = 'Ex VAT'
}

export const carImgPlaceholder = './assets/photos/car-placeholder.png';
@Component({
  selector: 'app-car-shop-card',
  templateUrl: './car-shop-card.component.html',
  styleUrls: ['./car-shop-card.component.scss']
})

export class CarShopCardComponent implements OnInit {
  faHeart = faHeart;

  @Input() car: CarDetails | undefined;
  @Input() isFavoritesPage: boolean = false;
  @Input() isClosedSales: boolean = false;

  carBusinessCase: BusinessCase | undefined;

  imgUrl: string = '';
  isFav = false;

  isNew = false;

  availableFrom = new Date();
  availableTo = new Date();

  deliveredTo = this.coreService.userInfo?.country.country;

  vatStatus = VAT;

  carStatus = CarStatus;
  offerStatus = OfferClientStatuses;

  constructor(private router: Router,
    private shopService: ShopService,
    private userService: UserService,
    private snackbar: SnackbarService,
    private coreService: CoreService,
    private offersService: OffersService,
    private dialog: MatDialog,
    private cartService: CartService) { }

  ngOnInit(): void {
    this.imgUrl = this.car?.profilePhoto && this.car?.profilePhoto != 'None' ? this.car.profilePhoto : carImgPlaceholder;

    if (this.userService.carFavorites.find(c => c === this.car?.carMainInfoId)) {
      this.isFav = true;
    }

    let date = new Date();
    date.setDate(date.getDate() - 7);

    this.isNew = (new Date(this.car!.created_at * 1000)) > date;

    this.availableFrom = new Date(this.car!.available.from);

    this.availableTo = new Date(this.car!.available.to);

    if (this.car!.offerDetails && !this.car!.offerDetails.myOffer) {
      this.car!.offerDetails.offerStatus = 'newC2COffer';
    }

    if (this.car!.offerDetails && this.car!.offerDetails.offerStatus === OfferClientStatuses.Pending && this.car!.offerDetails.offeredBy === 'C2C') {
      this.car!.offerDetails.offerStatus = 'counterOffer';
    }
  }

  openMakeOfferModal() {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CreateOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        car: this.car
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.coreService.showProgressBar.next(true);

        if (this.car!.offerDetails) {
          let body: CounterOfferBody = {
            value: modalResp.value,
            expiresOn: modalResp.expiresOn,
            offerId: this.car!.offerDetails!.offerId,
          }

          this.makeCounterOffer(body);
        } else {
          let createOfferBody: CreateOfferBody = {
            cars: [{
              value: parseInt(modalResp.value),
              carMainInfoId: this.car!.carMainInfoId!
            }],
            expiresOn: modalResp.expDate,
            buyerId: this.coreService.userInfo!.buyerCompany.id
          }

          this.offersService.createOffer(createOfferBody).subscribe({
            next: resp => {
              this.car!.offerDetails = {
                expiresOn: new Date(createOfferBody.expiresOn),
                myOffer: true,
                offerStatus: "Pending",
                offerType: "CLIENT",
                offeredBy: "CLIENT",
                offerId: resp,
                value: createOfferBody.cars[0].value,
                proposedPrices: []
              }

              this.coreService.showProgressBar.next(false);

              this.snackbar.positiveSentiment('Offer created');
            },
            error: err => {
              this.coreService.showProgressBar.next(false);

              this.snackbar.negativeSentiment(err.error);
            }
          })
        }
      }
    });
  }

  openMakeCounterOfferModal() {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CounterOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        offer: this.car?.offerDetails,
        shopPrice: this.car?.sellingPrice
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.coreService.showProgressBar.next(true);

        let createOfferBody: CounterOfferBody = {
          value: modalResp.value,
          expiresOn: modalResp.expiresOn,
          offerId: this.car!.offerDetails!.offerId,
        }

        this.makeCounterOffer(createOfferBody);
      }
    });
  }

  makeCounterOffer(body: CounterOfferBody) {
    this.offersService.makeCounterOffer(body).subscribe({
      next: resp => {
        this.car!.offerDetails!.offeredBy = "CLIENT";
        this.car!.offerDetails!.offerStatus = OfferClientStatuses.Pending;
        this.car!.offerDetails!.value = body.value;
        this.car!.offerDetails!.expiresOn = body.expiresOn ? new Date(body.expiresOn) : this.car!.offerDetails!.expiresOn;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer sent');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  // goToCar() {
  //   this.router.navigate([`car/${this.car?.carMainInfoId}`]);
  // }

  addToFav() {
    if (this.isFav) {
      this.userService.carFavorites.splice(this.userService.carFavorites.findIndex(c => c === this.car?.carMainInfoId), 1);

      this.userService.removeFavoritesCar(this.car?.carMainInfoId!).subscribe(resp => {
      })
    } else {
      this.userService.carFavorites.push(this.car?.carMainInfoId!);

      this.shopService.addCarToFavorites(this.car!.carMainInfoId!).subscribe(resp => {
      })
    }

    this.isFav = !this.isFav;
  }

  addToCart() {
    this.shopService.addCarToCart(this.car?.carMainInfoId!).subscribe({
      next: resp => {
        this.cartService.emitCartCars();

        this.snackbar.positiveSentiment('Car added to cart');
      },
      error: err => {
        this.snackbar.negativeSentiment('Car already exists in cart');
      }
    });
  }

  isNan(value: any) {
    return isNaN(value);
  }
}
