import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Offer } from 'src/app/core/models/offer.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { OfferClientStatuses } from 'src/app/features/my-offers/my-offers.component';
import { VAT } from '../car-shop-card/car-shop-card.component';
import { CartCar } from 'src/app/core/models/car.model';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CartService } from 'src/app/core/services/cart.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

@Component({
  selector: 'app-nav-bar-after-login',
  templateUrl: './nav-bar-after-login.component.html',
  styleUrls: ['./nav-bar-after-login.component.scss']
})
export class NavBarAfterLoginComponent implements OnInit {
  loading = this.offerService.loadingOffers;
  loadingCart = this.cartService.loadingCart;
  destroyed = new Subject<void>();

  @Input() tab: string = '';
  navbarOpened = false;

  offers: {
    accepted: Offer[],
    countered: Offer[],
    newFromC2C: Offer[],
  } = {
      accepted: [],
      countered: [],
      newFromC2C: []
    };

  cartCars: CartCar[] = [];

  vatStatus = VAT;

  lastScrollPos = 0;

  @ViewChild('navbar') navbarDiv: ElementRef | undefined;

  constructor(private router: Router,
    private authService: AuthService,
    private offerService: OffersService,
    private shopService: ShopService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private cartService: CartService,
    private renderer: Renderer2,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    //load offers
    this.offerService.loadOffers();

    //load cart
    this.shopService.getUserBuyingList().pipe(takeUntil(this.destroyed)).subscribe(resp => {
      this.loadingCart.next(false);

      if (resp.id) {
        this.cartService.emitBuylist(resp);
        this.cartService.emitCartCarsValue(resp.cars);
      } else {
        this.cartService.emitCartCarsValue([]);
      }
    });

    //observable for cart changes
    this.cartService.cartCarsSubject$.subscribe(resp => {
      this.cartCars = resp;
    });

    //observable for offers changes
    this.offerService.offerSubject$.subscribe(resp => {
      this.offers = {
        accepted: [],
        countered: [],
        newFromC2C: []
      };

      resp.sort((a, b) => {
        return (new Date(a.createdAt) < new Date(b.createdAt)) ? 1 : -1;
      }).filter(o => o.offerStatus === OfferClientStatuses.Accepted || (o.offerStatus === OfferClientStatuses.Pending && o.proposedPrices[o.proposedPrices.length - 1].offeredBy === 'C2C')).slice(0, 5).forEach(o => {
        if (o.offerStatus === OfferClientStatuses.Accepted) {
          this.offers.accepted.push({
            ...o,
            latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
          });
        } else if (o.proposedPrices.length === 1 && o.proposedPrices[0].offeredBy === 'C2C') {
          this.offers.newFromC2C.push({
            ...o,
            latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
          });
        } else if (o.proposedPrices.length > 1 && o.proposedPrices[o.proposedPrices.length - 1].offeredBy === 'C2C') {
          this.offers.countered.push({
            ...o,
            latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();

    this.cartService.loadingCart.next(true);
  }

  goTo(page: string) {
    this.router.navigate([page]);
  }

  goToHome() {
    this.router.navigate(['search']);
  }

  goToShop() {
    this.router.navigate(['search']);
  }

  goToClosedSales() {
    this.router.navigate(['closed-sales']);
  }

  goToAccount() {
    this.router.navigate(['my-account']);
  }

  goToOffers() {
    this.router.navigate(['my-offers']);
  }

  goToCart() {
    this.router.navigate(['shopping-cart']);
  }

  toggleNavbar() {
    this.navbarOpened = !this.navbarOpened;
  }

  logout() {
    this.authService.logout();
  }

  getTotalCartSum() {
    return this.cartCars.map(c => c.carPricing.offerDetails.offeredPrice ? c.carPricing.offerDetails.offeredPrice : c.carPricing.shopPrice).reduce((a, b) => a! + b!, 0)!;
  }

  removeFromCart(carId: string) {
    this.coreService.showProgressBar.next(true);

    this.shopService.removeCarFromCart(carId).subscribe(resp => {
      this.cartCars.splice(this.cartCars.findIndex(c => c.carMainInfoId === carId), 1);

      this.coreService.showProgressBar.next(false);

      this.cartService.emitCartCarsValue(this.cartCars);

      this.snackbar.positiveSentiment('Car removed');
    });
  }

  trackGA(eventName: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
    this.googleAnalyticsService.trackEvent(eventName, eventCategory, eventAction, eventLabel, eventValue);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const elementRef = this.navbarDiv!.nativeElement;

    var currentScrollPos = window.scrollY;

    if (currentScrollPos > this.lastScrollPos) {
      this.renderer.addClass(elementRef, 'hidden');
    } else if (currentScrollPos === 0) {
      this.renderer.removeClass(elementRef, 'hidden');
    } else {
      this.renderer.removeClass(elementRef, 'hidden');
    }

    this.lastScrollPos = currentScrollPos;
  }
}
