import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { CartCar } from "../models/car.model";
import { ShopService } from "./shop.service";
import { BuyList } from "../models/order.model";

@Injectable({
  providedIn: 'root'
})

export class CartService {
  loadingCart = new BehaviorSubject<boolean>(true);

  private cartCarsSubject = new Subject<CartCar[]>();
  cartCarsSubject$ = this.cartCarsSubject.asObservable();

  private buylistSubject = new Subject<BuyList>();
  buylistSubject$ = this.buylistSubject.asObservable();

  constructor(private shopService: ShopService) {
  }

  //emit cart change with values from api response
  emitCartCars() {
    this.loadingCart.next(true);

    this.shopService.getUserBuyingList().subscribe(resp => {
      this.loadingCart.next(false);

      if (resp.id) {
        this.cartCarsSubject.next(resp.cars);
      }
    })
  }

  //emit buylist with values from api response
  emitBuylist(buylist: BuyList) {
    this.buylistSubject.next(buylist);
  }

  //emit cart change with values from emitter component
  emitCartCarsValue(cars: CartCar[]) {
    this.cartCarsSubject.next(cars);
  }
}
