<app-my-profile-page-layout [profilePage]="'offers'">
  <div *ngIf="!(loading | async);else placeholder">
    <app-tabs [tabs]="tabs" [tabSelected]="tabSelected" (clickEmitter)="changeTab($event)"
      [disabled]="(loading | async)"></app-tabs>

    <div *ngIf="tabSelected === 'acceptedOffers'" class="flex">
      <app-white-btn (clickEmitter)="getOffersCarsInfoExport()" [variant]="4">Download cars</app-white-btn>
    </div>

    <!-- offers grid -->
    <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6"
      *ngIf="offers.length > 0;else noOffers">

      <!-- offer card -->
      <div *ngFor="let offer of offers">
        <section class="bg-white flex flex-col justify-between h-full">
          <!-- card photo -->
          <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active">
            <div class="flex w-full">
              <img [id]="'redirect-car-'+ offer.carDetails.carMainInfoId +'-img'"
                [attr.data-cy]="'redirect-car-'+ offer.carDetails.carMainInfoId +'-img'"
                class="w-full object-cover rounded cursor-pointer h-60 xl:h-[calc(100vw*0.1)]"
                [src]=" offer.profilePhoto ? offer.profilePhoto : offer.defaultCarImg" alt="">
            </div>
          </a>

          <!-- car base info -->
          <div class="flex flex-col gap-3 py-4 border-b border-white-lines justify-between px-3">
            <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active"
              [id]="'redirect-car-'+ offer.carDetails.carMainInfoId +'-title-link'"
              [attr.data-cy]="'redirect-car-'+ offer.carDetails.carMainInfoId +'-title-link'"
              class="font-bold cursor-pointer whitespace-normal overflow-hidden">
              {{offer.carDetails.make}}
              {{offer.carDetails.model}},
              {{offer.carDetails.variant}}
            </a>

            <p class="text-xs text-white-700">VIN: {{offer.carDetails.vin}}</p>
            <p class="font-bold text-blue-ctaBlue">€{{offer.carDetails.shopPrice | number}}</p>
          </div>

          <!-- car data -->
          <div class="flex flex-wrap gap-3 text-blue-darkMauve px-3 mt-3">
            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{offer.carDetails.mileage | number}} KM</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{offer.carDetails.manufactureYear}}</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{offer.carDetails.gearbox}}</p>
            </div>

            <div class="flex gap-3 text-xs py-1 px-2 bg-white-bgGrey">
              <p>{{offer.carDetails.fuelType}}</p>
            </div>
          </div>

          <!-- offer info -->
          <div class="text-sm text-white-greyText px-3 my-3">
            <div class="flex items-center gap-3">
              <p class="text-base text-green font-bold">€{{offer.latestOffer | number}}</p>
              <p>offered price</p>
            </div>
            <p class="text-white-greyText mt-3">expires: {{offer.expiresOn | date : 'dd/MM/yyyy'}}</p>
          </div>

          <!-- card buttons -->
          <div [ngSwitch]="tabSelected" class="p-3 h-full flex flex-col justify-end">
            <div *ngSwitchCase="'rejectedOffers'" class="flex flex-col">
              <p>{{offer.offerStatus === statuses.Rejected ? 'Offer rejected' : 'Offer expired'}}</p>
            </div>

            <div *ngSwitchCase="'acceptedOffers'" class="flex flex-col gap-3">
              <app-blue-btn id="add-to-cart" (clickEmitter)="addToCart(offer)">Add to cart</app-blue-btn>

              <app-black-btn id="decline-offer" (clickEmitter)="declineOffer(offer)">Decline offer</app-black-btn>
            </div>

            <div *ngSwitchCase="'pendingOffers'">
              <div class="flex flex-col gap-3" *ngIf="offer.clientConfirmation === statuses.Pending">
                <app-blue-btn id="accept-offer" (clickEmitter)="acceptOffer(offer)">
                  <p>Select for analysis/ordering</p>
                </app-blue-btn>

                <app-black-btn id="decline-offer" (clickEmitter)="declineOffer(offer)">Decline offer</app-black-btn>
              </div>
            </div>

            <div *ngSwitchCase="'proposedOffers'">
              <div class="flex flex-col gap-3" *ngIf="offer.clientConfirmation === statuses.Pending">
                <app-blue-btn id="accept-offer" (clickEmitter)="acceptOffer(offer)">
                  <p>Select for analysis/ordering</p>
                </app-blue-btn>

                <app-black-btn id="decline-offer" (clickEmitter)="declineOffer(offer)">Decline offer</app-black-btn>
              </div>
            </div>

            <div class="mt-3 flex flex-col gap-3">
              <app-white-btn *ngIf="tabSelected != 'finishedOffers'" id="open-counter-offer-modal"
                (clickEmitter)="openCounterOfferModal(offer)" [variant]="2">Make counter
                offer</app-white-btn>

              <app-green-btn (clickEmitter)="openCarModal(offer.carDetails.carMainInfoId)">View
                car</app-green-btn>
            </div>
          </div>
        </section>
      </div>
    </div>

    <mat-paginator class="mt-6" [pageIndex]="pageNo" [pageSize]="itemsPerPage" [length]="noOfOffers"
      (page)="changePage($event)"></mat-paginator>
  </div>

</app-my-profile-page-layout>

<ng-template #placeholder>
  <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
    <app-car-card-placeholder *ngFor="let i of [].constructor(10)"></app-car-card-placeholder>
  </div>
</ng-template>

<ng-template #noOffers>
  <div
    class="col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5 flex justify-center p-6 h-full flex-col">
    <app-no-results-placeholder [iconClass]="'far'" [icon]="'star'">
    </app-no-results-placeholder>

    <div class="self-center mt-6">
      <p class="text-2xl">No offers found!</p>
    </div>
  </div>
</ng-template>
