import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tob',
  templateUrl: './tob.component.html',
  styleUrls: ['./tob.component.scss']
})
export class TobComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToElement($element: any): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
