import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {
  cookieConsent = false;

  trackEvent(eventName: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
    if(!this.cookieConsent) return;

    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue
    })
  }

  constructor(private dialog: MatDialog) { }

  acceptConsent() {
    this.cookieConsent = true;

    gtag('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      analytics_storage: 'granted',
    })
  }


  denyConsent() {
    this.cookieConsent = false;

    gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
    })
  }
}
