<div class="max-h-[80vh] p-6 bg-white ">
  <div class="text-2xl flex items-center gap-2">
    <img class="!w-5 !h-5" src="./assets/logos/c2c-short-blue-logo.svg" alt="Cars2click logo" />
    Cars2click
  </div>
  <h2 class="text-2xl my-8">Cookies & Data Consent</h2>
  <p>
    We and our business partners use technologies, including cookies, to collect information
    about you for various purposes, including: Functional, Statistical, and Marketing.
  </p>
  <p>
    By clicking "Accept", you give your consent for all these purposes. You can also choose to
    specify the purposes you consent to by ticking the checkbox next to the purpose and clicking
    "Save settings".
  </p>
  <p>
    You may withdraw your consent at any time by clicking the small icon at the bottom left
    corner of the website.
  </p>
  <p>
    You can read more about how we use cookies and other technologies and how we collect and
    process personal data by clicking the link.
    <a href="https://cars2click.com/cookie-policy" target="_blank" class="underline text-blue-700"> Read more about
      cookies</a>
  </p>

  <p>Accept to continue or decline to exit the site.</p>

  <a href="https://policies.google.com/privacy?hl=en" target="_blank" class="underline text-blue-700 my-8">Google privacy policy</a>

  <div class="flex gap-6 justify-end flex-wrap">
    <secondary-btn id="btn-decline" (clickEmitter)="closeModal(false)">Decline</secondary-btn>
    <app-blue-btn id="btn-accept" (clickEmitter)="closeModal(true)">Accept</app-blue-btn>
  </div>
</div>
