import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './app-btn.component.html',
  styleUrls: ['./app-btn.component.scss']
})
export class AppBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() borderColor: string | undefined;
  @Input() bgColor: string | undefined;
  @Input() textColor: string | undefined;
  @Input() bgHoverColor: string | undefined;
  @Input() borderHoverColor: string | undefined;
  @Input() textHoverColor: string | undefined;
  @Input() id = 'id';

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
