import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, forkJoin, of } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CreateOfferModalComponent } from './create-offer-modal/create-offer-modal.component';
import { OffersService } from 'src/app/core/services/offers.service';
import { CreateOfferBody, Offer, OfferShortInfo } from 'src/app/core/models/offer.model';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { FormControl } from '@angular/forms';
import { BusinessCaseBody, Country, TransportMatrix } from 'src/app/core/models/utils.model';
import { ShopService } from 'src/app/core/services/shop.service';
import { UserService } from 'src/app/core/services/user.service';
import { VAT } from 'src/app/shared/car-shop-card/car-shop-card.component';
import { CartService } from 'src/app/core/services/cart.service';
import { OfferClientStatuses } from '../my-offers/my-offers.component';
import { CounterOfferModalComponent } from '../my-offers/counter-offer-modal/counter-offer-modal.component';

@Component({
  selector: 'app-car-page',
  templateUrl: './car-page.component.html',
  styleUrls: ['./car-page.component.scss']
})
export class CarPageComponent implements OnInit {
  isLoadingPage = new BehaviorSubject<boolean>(true);

  car: CarDetails | undefined;
  images: string[] = [];
  standardEquipments: string[] = [];
  equipments: string[] = [];
  carAlternatives: CarDetails[] = [];

  userInfo = this.coreService.userInfo!;

  countries: Country[] = [];
  transportMatrix: TransportMatrix[] = [];

  storesDropdown: DropdownOption[] = this.userInfo.buyerStores.map(s => ({ value: s.id, viewValue: s.store }));
  storeControl = new FormControl(this.storesDropdown[0].value);
  storeCountry = this.coreService.userInfo!.buyerCompany.country.country;

  offer: OfferShortInfo | undefined;

  isFav = false;

  vat = '';

  offerStatus = OfferClientStatuses;

  constructor(private activatedRoute: ActivatedRoute,
    private carService: CarService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private offerService: OffersService,
    private shopService: ShopService,
    private userService: UserService,
    private cartService: CartService,
    private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.isLoadingPage.next(true);

      if (params['carId']) {
        let carId = params['carId'];

        forkJoin({
          car: this.carService.getCarDetails(carId),
          countries: this.coreService.getCountries(),
          favsCars: this.userService.getUserFavoritesCars(),
          carPhotos: this.carService.getCarPhotos(carId),
          carMasterEquipments: this.carService.getCarMasterEquipments(carId),
          carC2VEquipments: this.carService.getCarC2VEquipments(carId),
          carAlternatives: this.carService.getCarAlternatives(carId).pipe(catchError(err => of(err))),
        }).subscribe({
          next: resp => {
            this.car = resp.car;
            this.countries = resp.countries;

            this.vat = this.car!.vatStatus ? VAT.ExVAT : VAT.IncludedVAT;

            if (resp.favsCars.id) {
              this.isFav = resp.favsCars.cars.some(c => c.carMainInfoId === carId);
            }

            let businessCaseBody: BusinessCaseBody = {
              countries: [this.countries.find(c => c.id.replace(/-/g, '') === this.userInfo.country.id)!.iso],
              make: resp.car.make,
              model: resp.car.model,
              manufactureYear: resp.car.manufactureYear,
              fuelType: resp.car.fuelType,
              gearbox: resp.car.gearbox,
              bodyType: resp.car.bodyType,
              variant: resp.car.variant,
              mileage: resp.car.mileage,
              enginePower: resp.car.enginePower,
              carMainInfoId: resp.car.carMainInfoId,
              firstReg: resp.car.firstReg.toString(),
              accessories: []
            };


            if (!resp.carAlternatives.error && resp.carAlternatives.similarTechCars) {
              this.carAlternatives = resp.carAlternatives.similarTechCars.concat(resp.carAlternatives.similarMakeCars).slice(0, 5);
            }

            this.images = resp.carPhotos.map((p: any) => p.originalPhoto!);
            this.images = this.images.length > 0 ? this.images : [resp.car.profilePhoto];

            if (resp.carMasterEquipments.standardEquipment) {

              this.addEquipmentToArray(this.equipments, resp.carMasterEquipments.optionalEquipment);
              this.addEquipmentToArray(this.equipments, resp.carMasterEquipments.packEquipment);
              this.addEquipmentToArray(this.standardEquipments, resp.carMasterEquipments.standardEquipment);
            }

            this.car!.locationName = this.coreService.countries.find(c => c.id === resp.car!.location)!.name;

            this.offer = resp.car.offerDetails;

            if (this.offer) this.offer.offerStatus = this.getOfferStatus(this.offer)

            //add car custom eq to list
            this.equipments = [...this.equipments, ...resp.carC2VEquipments.customEquipment.map(c => this.splitWordByUnderline(c))];

            this.isLoadingPage.next(false);
          },
          error: (err) => {
            this.isLoadingPage.next(false);

            if (err.status === 404) this.router.navigate(['not-found']);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.router.routerState.snapshot.url.match('/search') || this.router.routerState.snapshot.url.match('/closed-sales')) {
      sessionStorage.setItem('car-view-id', this.car!.carMainInfoId);
    }
  }

  addEquipmentToArray(array: string[], arrayOfEquipments: any[]) {
    let eq = arrayOfEquipments.map(eq => Object.getOwnPropertyNames(eq)).flat(1);

    eq.forEach(e => {
      array.push(this.splitWordByCamelcase(e));
    })
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  splitWordByCamelcase(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  splitWordByUnderline(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replaceAll('_', ' ');
  }

  copyAlert() {
    this.snackbar.positiveSentiment('Vehicle id copied to clipboard!');
  }

  openMakeOfferModal() {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CreateOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        car: this.car
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.coreService.showProgressBar.next(true);

        let createOfferBody: CreateOfferBody = {
          cars: [{
            value: parseInt(modalResp.value),
            carMainInfoId: this.car!.carMainInfoId!
          }],
          expiresOn: modalResp.expDate,
          buyerId: this.userInfo.buyerCompany.id
        }

        this.offerService.createOffer(createOfferBody).subscribe({
          next: resp => {
            this.offer = {
              expiresOn: new Date(createOfferBody.expiresOn),
              myOffer: true,
              offerStatus: "Pending",
              offerType: "CLIENT",
              offeredBy: "CLIENT",
              offerId: resp,
              value: createOfferBody.cars[0].value,
              proposedPrices: []
            }

            this.coreService.showProgressBar.next(false);

            this.snackbar.positiveSentiment('Offer created');
          },
          error: err => {
            this.coreService.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    });
  }

  addToCart() {
    this.shopService.addCarToCart(this.car!.carMainInfoId).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Car added to cart');

        this.cartService.emitCartCars();
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  addToFavs() {
    if (this.isFav) {
      this.userService.removeFavoritesCar(this.car?.carMainInfoId!).subscribe(resp => { })
    } else {
      this.shopService.addCarToFavorites(this.car!.carMainInfoId!).subscribe(resp => { })
    }

    this.isFav = !this.isFav;
  }

  goBack() {
    this.router.navigate([`${this.shopService.shopURLCache === 'closed-sales' ? 'closed-sales' : 'search'}`]);
  }

  isNaN(value: any) {
    return isNaN(value);
  }

  downloadFile(docId: string) {
    this.carService.downloadCarDocument(this.car!.carMainInfoId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  acceptOffer(offer: OfferShortInfo) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offerService.acceptOffer(offer.offerId).subscribe({
      next: resp => {
        this.cartService.emitCartCars();

        this.offer!.offerStatus = this.offerStatus.Accepted;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer accepted');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err);
      }
    });
  }

  declineOffer(offer: OfferShortInfo) {
    if (this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offerService.declineOffer(offer.offerId).subscribe({
      next: resp => {
        this.offer!.offerStatus = this.offerStatus.Rejected;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer declined');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err);
      }
    });
  }

  openCounterOfferModal(offer: OfferShortInfo) {
    if (this.coreService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CounterOfferModalComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        offer: offer,
        shopPrice: this.car?.sellingPrice
      }
    });

    dialogRef.afterClosed().subscribe(counter => {
      if (counter) {
        this.coreService.showProgressBar.next(true);

        let body = {
          offerId: offer.offerId,
          value: counter.value,
          expiresOn: counter.expiresOn
        }

        this.offerService.makeCounterOffer(body).subscribe({
          next: resp => {
            this.offer!.offerStatus = this.offerStatus.Pending;
            this.offer!.value = body.value;

            if (body.expiresOn) this.offer!.expiresOn = body.expiresOn;

            this.coreService.showProgressBar.next(false);

            this.snackbar.positiveSentiment('Counter offer created');
          },
          error: err => {
            this.coreService.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err);
          }
        })
      }
    });
  }

  private getOfferStatus(o: OfferShortInfo) {
    if (o.offerStatus === OfferClientStatuses.Pending && o.offerType === 'C2C' && o.proposedPrices.length === 1) {
      return OfferClientStatuses.C2CNewOffer;
    }
    if (o.offerStatus === OfferClientStatuses.Pending && o.proposedPrices[o.proposedPrices.length - 1].offeredBy === 'C2C') {
      return OfferClientStatuses.CounterOffer;
    } else {
      return o.offerStatus;
    }
  }
}
