<div class="bg-[#515151] h-screen flex items-center" [ngClass]="{'!bg-white' : isLoggedIn()}">
  <div class="mx-auto" *ngIf="!isLoggedIn();else placeholder">
    <div class="p-4 flex bg-black gap-4 items-center">
      <img src="./assets/logos/c2c-logo-blue-black-64.svg" alt="" class="w-[3.125rem]">

      <h2 class="text-white">Buyer web </h2>
    </div>

    <div class="p-9 text-center bg-white">
      <h1 class="font-bold text-xl mb-5 xl-mb-9">C2C Buyer Web - Log in</h1>

      <p class="mb-9 xl:mb-10">C2C Buyer Web is the centralized onboarding and account management tool<br>for
        buyers doing business with Cars2click.</p>

      <div class="flex mb-3 justify-center">
        <app-blue-btn class="w-fit" id="login-to-admin" (clickEmitter)="login()">Login to buyer
          account</app-blue-btn>
      </div>

      <a class="text-sm" href= "mailto: info@cars2click.com"> Login help </a>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="container h-[100vh] flex flex-col justify-center gap-9 text-center">
    <div class="w-full lg:w-2/3 2xl:w-full self-center">
      <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
    </div>

    <p class="text-sm text-white-greyText">Loading, please wait. Do not exit this page.</p>
  </div>
</ng-template>
