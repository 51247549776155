import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';
import { CookieModalComponent } from 'src/app/features/cookie-modal/cookie-modal.component';

@Component({
  selector: 'app-footer-after-login',
  templateUrl: './footer-after-login.component.html',
  styleUrls: ['./footer-after-login.component.scss']
})
export class FooterAfterLoginComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
  }

  openCookieModal() {
    const dialogRef = this.dialog.open(
      CookieModalComponent, {
      width: '750px',
      maxWidth: '90vw',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(cookieConsentResp => {
      localStorage.setItem('cookieConsent', JSON.stringify(cookieConsentResp));

      if (cookieConsentResp) {
        this.gaService.acceptConsent();
      } else {
        this.gaService.denyConsent();
      }
    });
  }
}
