import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl!: string;

  value = Math.floor(Math.random() * (50 - 30) + 30);

  constructor(private msalService: MsalService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.value += Math.floor(Math.random() * (50 - 30) + 30);
    }, 2000);

    this.authService.isLoggedInRedirect$.subscribe(resp => {
      if (resp) {
        this.router.navigate(['search']);
      }
    });
  }

  login() {
    this.msalService.loginRedirect();
  }

  isLoggedIn() {
    return this.msalService.instance.getActiveAccount() != null;
  }
}
