<div class="bg-black-darkChipHover">
  <div class="container grid grid-cols-2 md:grid-cols-5 py-9 text-sm text-white-greyText gap-6">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4">
      <div class="flex gap-3 items-center">
        <img class="max-w-[2.5rem] md:max-w-none" src="./assets/logos/c2c-short-blue-logo.svg">

        <p class="font-bold text-white">Cars2click</p>
      </div>

      <p class="max-w-[12rem]">AI led B2B cross-border car trading</p>
    </div>

    <div class="flex flex-col gap-2 text-sm md:text-base">
      <p class="font-bold text-white-bgGrey text-base mb-1">SOCIAL MEDIA</p>

      <a class="w-fit hover:text-white-bgGrey" target="_blank"
        href="https://www.linkedin.com/company/cars2click/">LinkedIn</a>

      <a class="w-fit hover:text-white-bgGrey" target="_blank"
        href="https://www.youtube.com/channel/UCFutXDUcQESgj_TmEnEGr0A">YouTube</a>

      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://www.facebook.com/cars2click">Facebook</a>

      <a class="w-fit hover:text-white-bgGrey" target="_blank"
        href="https://www.instagram.com/lifeatcars2click/">Instagram</a>
    </div>

    <div class="flex flex-col gap-2 text-sm md:text-base">
      <p class="font-bold text-white-bgGrey text-base mb-1">DISCOVER</p>

      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/home">About us</a>

      <p>How we work</p>

      <a class="w-fit hover:text-white-bgGrey" target="_blank"
        href="https://cars2click.com/contact#contactForm">Contact</a>

      <p>Careers</p>
    </div>

    <div class="flex flex-col gap-2 text-sm md:text-base">
      <p class="font-bold text-white-bgGrey text-base mb-1">AI MARIA ECOSYSTEM</p>
      <p>AI Maria</p>

      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2view.com/">Cars2view</a>

      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/home">Cars2click</a>

      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2transport.com/">Cars2transport</a>
    </div>

    <div class="flex flex-col gap-2 text-sm md:text-base">
      <p class="font-bold text-white-bgGrey text-base mb-1">RESOURCES</p>
      <p>Newshub</p>
      <p>Support</p>
    </div>
  </div>
</div>

<div class="bg-black">
  <div class="container flex justify-between text-sm text-white-greyText py-4 flex-col sm:flex-row items-center gap-10">
    <p>© Cars2click AB 2019-2024</p>

    <div class="flex gap-6 items-center">
      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/imprint">Imprint</a>
      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/terms-of-use">Terms of
        use</a>
      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/privacy-policy">Privacy
        Policy</a>
      <a class="w-fit hover:text-white-bgGrey" target="_blank" href="https://cars2click.com/cookie-policy">Cookie
        Policy</a>

      <fa-icon (click)="openCookieModal()" class="text-white py-1 px-2 rounded bg-white-greyText cursor-pointer"
        [icon]="['fas','cookie-bite']"></fa-icon>
    </div>
  </div>
</div>
