<style>
  .MsftOfcThm_Background2_lumMod_75_Fill_v2 {
    fill: #B5B5B5;
  }
</style>

<div class="p-9 bg-[#152023] text-[#8C9293] text-lg">
  <div style="clear:both;">
    <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal;">&nbsp;</p>
  </div>

  <p class="text-5xl mb-12 text-white">Legal mentions</p>

  <p style="margin-top:0pt; margin-bottom:8pt;">This Website (https://shop.cars2click.com) is owned and controlled by
    Cars2click AB, with registered office at Barnhusgatan 8, 111 23, Stockholm, Sweden, duly registered at Swedish
    Companies Registration Office under the number 559192-9053, with a share capital of 50.000 SEK, hereinafter referred
    to as &ldquo;Cars2click&rdquo;.</p>

  <p style="margin-top:0pt; margin-bottom:8pt;">Through the Website, any professional (hereinafter referred to as
    &ldquo;User&rdquo;) may buy cars with Cars2click (together referred to as &ldquo;Parties&rdquo;).</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">The Parties agree that purchases made through this Website shall be
    governed exclusively by the Terms of Use, as well as the Terms and Conditions.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">By using our Website, the User expressly accepts the Terms of Use and
    must therefore read them carefully. If the User does not accept the Terms of Use,<span
      style="color:#ff0000;">&nbsp;</span>the User should leave the Website.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">&nbsp;</p>
  <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:28pt;"><span
      style=" letter-spacing:-0.5pt; color:#25393e;">&nbsp;</span></p>


  <p class="text-5xl mb-4 text-white">Terms of Buying</p>

  <p style="margin-top:0pt; margin-bottom:8pt;" class="text-2xl mb-4 text-white">Cars2click</p>
  <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:9pt;"><span
      style="color:#a6a6a6;">&nbsp;</span></p>
  <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:9pt;"><span
      style="color:#a6a6a6;">&nbsp;</span></p>

  <table cellspacing="0" cellpadding="0" style=" width: 100%;">
    <tbody>
      <tr>
        <td style="width:422.4pt; padding-right:14.2pt; padding-left:14.2pt; vertical-align:top;">
          <p
            style="margin-top:12pt; margin-bottom:12pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size:10pt;">
            <strong><span>Contents</span></strong>
          </p>
          <p style="margin-top:0pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;"><a
              class="cursor-pointer" (click)="scrollToElement(_Toc178761302)"
              style="text-decoration:none;"><strong><span style="font-size:8pt; ">1.</span></strong><strong><span
                  style="width:17.33pt; color:#000000; display:inline-block;">&nbsp;</span></strong><strong><span
                  style="font-size:8pt; ">General Part</span></strong></a></p>
          <p style="margin-top:0pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;"><a
              class="cursor-pointer" (click)="scrollToElement(_Toc178761303)"
              style="text-decoration:none;"><strong><span style="font-size:8pt; ">2.</span></strong><strong><span
                  style="width:17.33pt; color:#000000; display:inline-block;">&nbsp;</span></strong><strong><span
                  style="font-size:8pt; ">Terms of use</span></strong></a></p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement(_Toc178761304)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.1.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Use of Website</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761305)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.2.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Reservation and Orders</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:24pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761306)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.2.1.</span><span
                style="width:27.98pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Order submission</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:24pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761307)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.2.2.</span><span
                style="width:27.98pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Pre-Sales</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:24pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761308)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.2.3.</span><span
                style="width:27.98pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Estimated Damages</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761309)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.3.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Transport</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761310)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.4.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Documents</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761311)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.5.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Claims Policy</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:24pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761312)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.5.1.</span><span
                style="width:27.98pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Vehicle &ldquo;Showroom ready&rdquo;</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:24pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761313)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.5.2.</span><span
                style="width:27.98pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Vehicles &ldquo;not-Showroom ready&rdquo;</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761314)" style="text-decoration:none;"><span
                style="font-size:8pt; ">2.6.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">Cancellation and Refunds</span></a>
          </p>
          <p style="margin-top:0pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;"><a
              class="cursor-pointer" (click)="scrollToElement( _Toc178761315)"
              style="text-decoration:none;"><strong><span style="font-size:8pt; ">3.</span></strong><strong><span
                  style="width:17.33pt; color:#000000; display:inline-block;">&nbsp;</span></strong><strong><span
                  style="font-size:8pt; ">Data Protection and Confidentiality</span></strong></a></p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761316)" style="text-decoration:none;"><span
                style="font-size:8pt; ">3.1.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">PRIVACY AND DATA PROTECTION</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761317)" style="text-decoration:none;"><span
                style="font-size:8pt; ">3.2.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">APPLICABLE LAW</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761318)" style="text-decoration:none;"><span
                style="font-size:8pt; ">3.3.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">GENERAL PROVISIONS</span></a>
          </p>
          <p
            style="margin-top:0pt; margin-left:12pt; margin-bottom:5pt; text-align:justify; line-height:115%; font-size:12pt;">
            <a class="cursor-pointer" (click)="scrollToElement( _Toc178761319)" style="text-decoration:none;"><span
                style="font-size:8pt; ">3.4.</span><span
                style="width:22.66pt; color:#000000; display:inline-block;">&nbsp;</span><span
                style="font-size:8pt; ">CHANGES TO THE TERMS OF USE</span></a>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:115%; font-size:12pt;">&nbsp;</p>
        </td>
      </tr>
    </tbody>
  </table>


  <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:9pt;"><span
      style="color:#a6a6a6;">&nbsp;</span></p>
  <p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:9pt;"><span
      style="color:#a6a6a6;">&nbsp;</span></p>
  <p style="margin-top:0pt; margin-bottom:8pt;"><a #_Toc166836324><br style="page-break-before:always; clear:both;"></a>
  </p>

  <a #_Toc178761302>
    <p class="title">1. General Part</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Read the Terms and Conditions carefully as it contains important
    information about the relationship between the parties.</p>

  <a #_Toc178761303>
    <p class="title">2. Terms of use</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">The Terms apply to the online order. The Terms and Conditions
    (&quot;Terms&quot;) will apply when the customer (&quot;you&quot; or &quot;Customer&quot;) make an online order and
    reservation (together an &quot;Order&quot;) for a vehicle advertised for sale (&quot;Vehicle&quot;,
    &ldquo;Car&rdquo;) on Cars2click Online Shop at <span class="link">www.shop.cars2click.com</span> or <a
      name="_cmntref3">any
      other Cars2click
      platform (including mobile and tablet) (&quot;Platform&quot;).</a><a href="#_cmnt3"
      style="text-decoration:none;"><span style="font-size:8pt;"></span></a></p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Please read the Terms carefully before making an Order on Platform. The
    Terms form the contract between the parties in respect to an Order and the use of the Services and will be binding
    on all users of the Services. We suggest that the user also keep a copy of the Terms for future reference.</p>

  <a #_Toc178761304>
    <p class="subtitle">2.1 Use of Website</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">The information provided on the website, or which can be obtained from
    it, although accurate, does not dispense with its confirmation, nor can it be considered binding, which is why it
    cannot be used as a substitute for any form of advice. Decisions taken based on this information are the user sole
    responsibility.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Although Cars2click works to provide accurate, complete and up to date
    information obtained from sources considered reliable, Cars2click does not guarantee, either expressly or
    implicitly, that the information provided on the website or that may be obtained through it is accurate and complete
    and/or up to date.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click controls and maintains the website <a name="_cmntref4">for
      several European countries</a><a href="#_cmnt4" style="text-decoration:none;"><span
        style="font-size:8pt;"></span></a>, and therefore does not guarantee that the information contained in it
    or that may be obtained from it is suitable for use in other locations or is available there. If the user access the
    website, the user is responsible for complying with the applicable legislation in his company&rsquo;s location.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click does not warrant that the website will operate error-free
    and/or without interruption.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;"><strong>The Terms may change from time to time</strong> so the user
    should check the Terms each time the services are used. Cars2click reserves all rights to change any or all the
    Terms at any time and will post the updated Terms of Use on the Website. All changes are effective immediately and
    the continued use of the Website, Services or Products after the posting of any amended Terms shall constitute an
    agreement to be bound by any such changes.</p>

  <a #_Toc178761305>
    <p class="subtitle">2.2 Reservation and Orders</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">The next terms will have the following meanings unless the context
    otherwise requires:</p>

  <table cellspacing="0" cellpadding="0" style="border-collapse: collapse; width: 100%;">
    <tbody>
      <tr>
        <td style="width:88.45pt; color:white; vertical-align:middle;">
          <p><strong>Term</strong></p>
        </td>
        <td style="width:340.75pt;color:white; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle;">
          <p><strong>Meaning</strong></p>
        </td>
      </tr>
      <tr>
        <td style="width:88.45pt; padding-right:5.4pt;  vertical-align:middle; color:white;">
          <p>Reservation
          </p>
        </td>
        <td style="width:340.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle; ">
          <p>Reservation means the confirmed
            reservation of the Vehicle, once the user have paid the Reservation Payment, in accordance with the Terms.
          </p>
        </td>
      </tr>
      <tr>
        <td style="width:88.45pt; padding-right:5.4pt;vertical-align:middle;color:white;">
          <p>Reservation
            Payment</p>
        </td>
        <td style="width:340.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle;">
          <p>Reservation Payment means the
            amount to be paid by the user for the Reservation.</p>
        </td>
      </tr>
      <tr>
        <td style="width:88.45pt; padding-right:5.4pt;  vertical-align:middle; color:white;">
          <p>Vehicle</p>
        </td>
        <td style="width:340.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle; ">
          <p>Vehicle means the vehicle the
            user is interested in purchasing.</p>
        </td>
      </tr>
      <tr>
        <td style="width:88.45pt; padding-right:5.4pt;  vertical-align:middle;color:white;">
          <p>Order</p>
        </td>
        <td style="width:340.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle;">
          <p>A purchase Order is a
            commercial document issued by the user to Cars2click, indicating types, quantities, and agreed prices for
            products or services required. An Order is a legally binding document that confirms that payment will be
            made for goods at a later date. It should contain the amount due to be paid to Cars2click, the method of
            payment, and a concrete date for the agreed-upon payment.</p>
        </td>
      </tr>
      <tr>
        <td style="width:88.45pt; padding-right:5.4pt;  vertical-align:middle; color:white;">
          <p>CMR</p>
        </td>
        <td style="width:340.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:middle; ">
          <p>CMR is a document that contains
            the rights and obligations of parties involved in European road transport. These are the shipper, the
            carrier and the consignee. The CMR document is referred to in the event of any discrepancy at the time of
            collection or delivery.</p>
        </td>
      </tr>
    </tbody>
  </table>

  <a #_Toc178761306>
    <p class="subtitle">2.2.1 Order submission</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Once the user have submitted the Order, an Order confirmation email wil
    be sent from Cars2click. <a name="_cmntref5"></a><a name="_cmntref6">The Vehicle is not reserved until the user have
      made the Payment in full, completed the Order and have received the a confirmation email</a><a href="#_cmnt5"
      style="text-decoration:none;"><span style="font-size:8pt;"></span></a><a href="#_cmnt6"
      style="text-decoration:none;"><span style="font-size:8pt;"></span></a>. The submission of the order is
    binding. If the user wishes to make changes in the Order once it&rsquo;s been submitted, the user should contact
    <span class="link">sales&#64;cars2click.com.</span>
  </p>
  <p style="margin-top:0pt; margin-bottom:8pt;">A certified Order Confirmation will be sent by Cars2click through his
    partners with Scrive AB (&ldquo;Scrive&rdquo;). The user will receive an email from Scrive requesting to confirm the
    Order placed and the agreement of the Terms and Conditions.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">After the confirmation via Scrive, the invoices will be issued and sent
    to the User<span style="color:#ff0000;">.</span></p>
  <p style="margin-top:0pt; margin-bottom:8pt;">The<a name="_cmntref7"></a><a name="_cmntref8">&nbsp;Payment&nbsp;</a><a
      href="#_cmnt7" style="text-decoration:none;"><span style="font-size:8pt;"></span></a><a href="#_cmnt8"
      style="text-decoration:none;"><span style="font-size:8pt;"></span></a>is a sum to be paid for ordering the
    Vehicle(s). It is not a Part payment or deposit towards the price of the Vehicle, and it does not guarantee that the
    Vehicle will be sold to the user or that the Vehicle will be available to be purchased.</p>

  <a #_Toc178761307>
    <p class="subtitle">2.2.2 Pre-Sales</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">All Vehicles sold as pre-sales have a mandatory reservation fee of 10%
    Ex-VAT of the amount of each vehicle, due immediately.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">When the vehicle is available the user is notified, and the outstanding
    balance should be paid immediately. If the invoice is not paid in time Cars2click reserves the right to cancel the
    deal and charge a cancelation fee.</p>

  <a #_Toc178761308>
    <p class="subtitle">2.2.3 Estimated Damages</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">The damages presented in Cars2click Order Confirmation are estimations
    provided by a third party and may vary depending on the source of estimation. These estimations are for
    informational purposes only and do not represent discounts. Any applicable discounts have been previously discussed
    with the Key Account Manager assigned to the user account. Please note that the actual number of damages may differ
    from the estimated amount provided.</p>

  <a #_Toc178761309>
    <p class="subtitle">2.3 Transport</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click offers a transport service to all Vehicles presented on
    Cars2click Online Shop. The transportation service is provided by Cars2transport Europe AB
    (&ldquo;Cars2transport&rdquo;) and is subject to Cars2transport&rsquo;s Terms and Conditions.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Once an Order is placed, Cars2transport starts planning the
    transportation of the Vehicle(s). If the outstanding payment is not received within the agreed date, the transport
    booking is cancelled due to non-payment. In such a case, Cars2click will be compelled to charge the user for the
    transport cancellation, as outlined in the Terms and Conditions.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">If the payment is not made promptly, the user will not be eligible to
    claim any compensation for the transport cancellation or delay.</p>

  <a #_Toc178761310>
    <p class="subtitle">2.4 Documents</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">The documents of the Vehicle(s) bought on shop.cars2click.com are made
    available online and shipped after the reception of the original CMR. The delivery time depends on the country of
    origin and delivery of the Vehicle(s). It may not be sent at the same time as the vehicles.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click relies on other parties to ship the documents of the
    Vehicle(s) to the user.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">If there is a need to change the delivery address, the User must contact
    <span class="link">sales&#64;cars2click.com.</span>
  </p>

  <a #_Toc178761311>
    <p class="subtitle">2.5 Claims Policy</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Claims for lost documents longer than 2 months after delivery will not
    be accepted.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Damages must be noted on CMR at delivery to be accepted.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Photo documentation of damages, not presented in the offer, including
    cost estimation per damage must be emailed to sales&#64;cars2click.com latest 24h after delivery to be accepted.</p>

  <a #_Toc178761312>
    <p class="subtitle">2.5.1 Vehicle &ldquo;Showroom
      ready&rdquo;</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;"><em>Show room ready</em> cars are used cars prepared for sale, in the
    normal condition for age and mileage so small damages are expected.</p>


  <a #_Toc178761313>
    <p class="subtitle">2.5.2 Vehicles &ldquo;not-Showroom
      ready&rdquo;</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Cars not-<em>show room ready</em> are used cars with damages reports and
    the customer confirm with the signature of the order to have received a report.</p>

  <a #_Toc178761314>
    <p class="subtitle">2.5.2 Cancellation
      and Refunds</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Orders cancelled after approval will be subject of a cancellation fee of
    10% Ex-VAT of the amount of the order.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">The user has the right to cancel an Order if the delivery is delayed
    more than 3 months (for used cars) and 6 months (for new cars) beyond the estimated delivery date.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">In case of cancellation or delay by Cars2click the user cannot claim
    damages or ask Cars2click to cover any costs or lost profits.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Refunds will be processed by Cars2click Operational Services only and
    will be paid to the same bank account that the<a name="_cmntref9">&nbsp;Payment&nbsp;</a><a href="#_cmnt9"
      style="text-decoration:none;"><span style="font-size:8pt;"></span></a>was made and may take up to 5 days to
    be processed. The exact timing of the refund payment depend on the user bank lead time.</p>

  <a #_Toc178761315>
    <p class="title">3 Data Protection and
      Confidentiality</p>
  </a>

  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click and the user will process the Personal Data of the parties
    involved in the contractual relationship as Data Controllers, for the purpose of managing the rights and obligations
    arising from the commercial relationship. In this case, each Party acts as Data Controller based on its legal
    obligations and legitimate interests, to the extent strictly necessary for the contacts and communications for the
    fulfilment of its obligations, in particular to send Order Confirmations and related communications.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">Cars2click and the Client ensure the compliance with the applicable Data
    Protection Legislation, namely with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
    April 2016.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">For more information on how Cars2click processes personal data, please
    consult its Privacy Policy available on the website.</p>
  <p style="margin-top:0pt; margin-bottom:8pt;">&nbsp;</p>

  <a #_Toc178761316>
    <p class="subtitle">3.1 PRIVACY AND
      DATA PROTECTION</p>
  </a>

  <ol type="1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-bottom:8pt; ">While navigating or submitting an offer on
      the Website, we may process your personal data. For more information on how we process your personal data, consult
      our&nbsp;<a href="https://cars2click.com/privacy-policy" style="text-decoration:none;"><u><span
            style="color:#45dfff;">Privacy Policy</span></u></a>, accessible&nbsp;<a
        href="https://cars2click.com/privacy-policy" style="text-decoration:none;"><u><span
            style="color:#45dfff;">here</span></u></a>.</li>
    <li style="margin-bottom:8pt; ">The use of cookies and other tracking
      technologies on this Website will be governed by our&nbsp;<a href="https://cars2click.com/cookie-policy"
        style="text-decoration:none;"><u><span style="color:#45dfff;">Cookie Policy</span></u></a>, accessible&nbsp;<a
        href="https://cars2click.com/cookie-policy" style="text-decoration:none;"><u><span
            style="color:#45dfff;">here</span></u></a>.</li>
  </ol>

  <a #_Toc178761317>
    <p class="subtitle">3.2 APPLICABLE
      LAW</p>
  </a>

  <ol type="1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-bottom:8pt; ">Without prejudice to the mandatory legal
      rules that may be applicable, these Terms of Use shall be governed and interpreted in accordance
      with&nbsp;Swedish&nbsp;Law. The User acknowledges and accepts the jurisdiction of the Judicial Court of the
      District of&nbsp;Stockholm,&nbsp;with express waiver of any other, to hear any action arising from and/or related
      to the Terms of Use and/or the use of this Website. Notwithstanding the above, Cars2click may apply for injunctive
      relief in any jurisdiction.</li>
  </ol>

  <a #_Toc178761318>
    <p class="subtitle">3.3 GENERAL
      PROVISIONS</p>
  </a>

  <ol type="1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-bottom:8pt; ">If any provision of the Terms of Use is
      found to be void, voidable, unenforceable, ineffective or unenforceable or is otherwise invalid by any entity
      having jurisdiction, including by a court of competent jurisdiction, the invalidity of any such provision shall
      not affect the validity of the remaining provisions of the Terms of Use, which shall remain in full force and
      effect.</li>
    <li style="margin-bottom:8pt; ">In the event of a declaration of invalidity
      pursuant to the above paragraph, the Terms of Use shall, to the fullest extent permitted by law, be interpreted so
      as to be as similar in effect as possible to that which was originally intended (even with the invalidated
      provision).</li>
  </ol>

  <a #_Toc178761319>
    <p class="subtitle">3.4 CHANGES TO THE
      TERMS OF USE</p>
  </a>

  <ol type="1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-bottom:8pt; ">If there are reasonable grounds, Cars2click
      may alter these Terms of Use at any time and without prior notice to the User. The User should consult these Terms
      of Use frequently and check compliance with them when using the Website, confirming the date of the latest version
      at the end of this document. Amendments will not be applied retroactively. The User hereby accepts that legal,
      technical or commercial reasons affecting Cars2click shall be considered as compelling reasons to alter these
      Terms of Use. Changes relating to new features of a service or changes made for legal reasons will come into force
      immediately. If the User does not accept the modified Terms of Use of the Website, the User should cease using the
      Website.</li>
  </ol>
</div>
