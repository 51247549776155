import { NgModule } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faChevronsLeft as falChevronsLeft, faBallPile, faCalendarRange, faCarSide, faCartShopping, faCircleInfo,
  faClipboard, faCloudMeatball, faEarthAmericas, faEngine, faFluxCapacitor, faGasPump, faGear,
  faRoad, faSackDollar, faScrewdriverWrench, faSmoke, faSteeringWheel, faSwatchbook, faTag,
  faTrashCan, faCircleCheck, faBriefcase, faNotebook, faBadgeDollar, faCircleUser,
  faRobot, faChevronsRight, faTruckFast, faTruckContainerEmpty, faUserVneckHair, faEnvelope, faPhone, faAddressCard, faList, faLocationPen, faBadgeCheck, faCirclePlus, faCoins, faRoute, faCoinVertical, faArrowDownToLine, faFlask, faGlobe
} from '@fortawesome/pro-light-svg-icons';
import { faHeart as farHeart, faFileLines as farFileLines, faBarsStaggered, faChartLine, faChevronDown, faChevronUp, faCopy, faHandshake, faPaperclip, faPlus, faUser, faXmark, faSquareUser as farSquareUser, faMoneyBill, faGift, faArrowLeft, faStar, faMagnifyingGlass, faChevronLeft, faChevronRight, faBarsFilter, faIdCard } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo as fasCircleInfo, faCircleUser as fasCircleUser, faTag as fasTag, faFileLines, faHeart, faChevronsRight as fasChevronsRight, faChevronsLeft, faChevronsUp, faChevronsDown, faCoins as fasCoins, faCircleArrowUp, faCalendarDays, faScaleBalanced, faSignal, faCircleCheck as fasCircleCheck, faSquareUser, faStopwatch, faLocationXmark, faCreditCard, faCircleXmark, faCircleExclamation, faTrashCan as fasTrashCan, faCars, faBasketShoppingSimple, faSquareArrowUpRight, faSquareArrowDownLeft, faCookieBite } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
  providers: [],
  bootstrap: [],
})
export class SharedFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faHeart);
    library.addIcons(faEarthAmericas);
    library.addIcons(faGear);
    library.addIcons(faClipboard);
    library.addIcons(faCalendarRange);
    library.addIcons(faRoad);
    library.addIcons(faSwatchbook);
    library.addIcons(faCarSide);
    library.addIcons(faSteeringWheel);
    library.addIcons(faGasPump);
    library.addIcons(faScrewdriverWrench);
    library.addIcons(faEngine);
    library.addIcons(faCircleInfo);
    library.addIcons(fasCircleInfo);
    library.addIcons(faBallPile);
    library.addIcons(faCloudMeatball);
    library.addIcons(faSmoke);
    library.addIcons(faFluxCapacitor);
    library.addIcons(faSackDollar);
    library.addIcons(faTag);
    library.addIcons(faCartShopping);
    library.addIcons(farHeart);
    library.addIcons(faTrashCan);
    library.addIcons(faCircleCheck);
    library.addIcons(faBriefcase);
    library.addIcons(faNotebook);
    library.addIcons(faBadgeDollar);
    library.addIcons(faCircleUser);
    library.addIcons(faRobot);
    library.addIcons(faChevronsRight);
    library.addIcons(faFileLines);
    library.addIcons(faTruckFast);
    library.addIcons(faTruckContainerEmpty);
    library.addIcons(faUserVneckHair);
    library.addIcons(faEnvelope);
    library.addIcons(faPhone);
    library.addIcons(faAddressCard);
    library.addIcons(fasChevronsRight);
    library.addIcons(faList);
    library.addIcons(faIdCard);
    library.addIcons(faLocationPen);
    library.addIcons(faHandshake);
    library.addIcons(faChevronsLeft);
    library.addIcons(falChevronsLeft);
    library.addIcons(faPaperclip);
    library.addIcons(faChevronsUp);
    library.addIcons(faChevronUp);
    library.addIcons(faChevronsDown);
    library.addIcons(faChevronDown);
    library.addIcons(faBadgeCheck);
    library.addIcons(faCirclePlus);
    library.addIcons(faUser);
    library.addIcons(faBarsStaggered);
    library.addIcons(faCoins);
    library.addIcons(fasCoins);
    library.addIcons(faRoute);
    library.addIcons(faCoinVertical);
    library.addIcons(faPlus);
    library.addIcons(faXmark);
    library.addIcons(faCopy);
    library.addIcons(faCircleArrowUp);
    library.addIcons(faCircleArrowUp);
    library.addIcons(faChartLine);
    library.addIcons(fasTag);
    library.addIcons(faCalendarDays);
    library.addIcons(faScaleBalanced);
    library.addIcons(faSignal);
    library.addIcons(fasCircleCheck);
    library.addIcons(faSquareUser);
    library.addIcons(farSquareUser);
    library.addIcons(faStopwatch);
    library.addIcons(faLocationXmark);
    library.addIcons(faMoneyBill);
    library.addIcons(faGift);
    library.addIcons(faCreditCard);
    library.addIcons(faCircleXmark);
    library.addIcons(faArrowDownToLine);
    library.addIcons(faArrowLeft);
    library.addIcons(fasCircleUser);
    library.addIcons(faCircleExclamation);
    library.addIcons(faStar);
    library.addIcons(faFlask);
    library.addIcons(farFileLines);
    library.addIcons(faMagnifyingGlass);
    library.addIcons(fasTrashCan);
    library.addIcons(faGlobe);
    library.addIcons(faCars);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(faBasketShoppingSimple);
    library.addIcons(faSquareArrowUpRight);
    library.addIcons(faSquareArrowDownLeft);
    library.addIcons(faBarsFilter);
    library.addIcons(faCookieBite);
  }
}
