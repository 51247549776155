import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CarSearchBody, CarSearchResponse, CarSpecificationResponse } from "../models/car.model";
import { BuyList, CreateOrderBody, UpdateCartBody } from "../models/order.model";
import { Constants, ShopCacheBody } from "../models/utils.model";
import { Offer } from "../models/offer.model";

@Injectable({
  providedIn: 'root'
})

export class ShopService {
  shopURLCache = '';

  constructor(private http: HttpClient) {
  }

  public getCarFilterClient(body: CarSearchBody): Observable<CarSearchResponse> {
    return this.http.post<CarSearchResponse>(`${environment.backendApiUrl}filter-cars-client`, body);
  }

  public getClosedSalesCarFilterClient(body: CarSearchBody): Observable<CarSearchResponse> {
    return this.http.post<CarSearchResponse>(`${environment.backendApiUrl}filter-closed-sales-cars-client`, body);
  }

  public getCarNomenclatorData(query: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public getCarSpecNomenclatorData(query: string): Observable<CarSpecificationResponse[]> {
    return this.http.get<CarSpecificationResponse[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public addCarToFavorites(carId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}add-to-wish-list`, { carMainInfoId: carId });
  }

  public addCarToCart(carId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}add-to-buy-list`, { carMainInfoId: carId });
  }

  public getAcceptedOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.backendApiUrl}get-accepted-offers`);
  }

  public getUserBuyingList(): Observable<BuyList> {
    return this.http.get<BuyList>(`${environment.backendApiUrl}get-buy-list`);
  }

  public removeCarFromCart(carId: string): Observable<any> {
    return this.http.delete<any>(`${environment.backendApiUrl}remove-from-buy-list`, { body: { carMainInfoId: carId } });
  }

  public placeOrder(order: CreateOrderBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}place-order`, order);
  }

  public getConstants(): Observable<Constants[]> {
    return this.http.get<Constants[]>(`${environment.backendApiUrl}get-constants`);
  }

  public updateCartOptions(body: UpdateCartBody) {
    return this.http.post<any>(`${environment.backendApiUrl}update-buy-list`, body);
  }

  public addCOCAllCars(cartId: string) {
    return this.http.post<any>(`${environment.backendApiUrl}update-coc-req-on-cars-from-buy-list`, { id: cartId });
  }

  public addPhotoEditAllCars(cartId: string) {
    return this.http.post<any>(`${environment.backendApiUrl}update-photo-edit-on-cars-from-buy-list`, { id: cartId });
  }
}
