import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './app-input-number.component.html',
  styleUrls: ['./app-input-number.component.scss']
})
export class AppInputNumberComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() label: string | undefined;
  @Input() componentId = 'id';
  @Input() min: number | undefined;
  @Input() max: number | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
